import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import {
	Login,
	PaymentFail,
	PaymentSuccess,
	ProfileAccount,
	ProfileSubscription,
	RegistrationComplete,
	RegistrationLayout,
	RegistrationForm,
	RegistrationPlan,
	Results,
	Search,
	SharedCandidate,
	LoginError,
	ProjectsAcquired,
	ProjectCandidates,
	Projects,
	Integrations,
	SignIn,
	SignUp,
	VerifyEmail,
	CompleteVerification,
	FinishVerification,
	ForgotPassword,
	ResetPassword,
	CompletePasswordReset,
	History,
	TechnographicsChat
} from 'pages';
import { ProfileLayout, MainLayout, ProjectsLayout } from 'layouts';
import { ErrorBoundary } from 'features/error-boundary';
import { MobileScreen, useIsMobile } from 'features/mobile';

import { ProtectedRoute } from './ProtectedRoute';
import { ProtectedExpiredRoute } from './ProtectedExpiredRoute';
import { SentryBrowserRouter } from './SentryBrowserRouter';

const router = createBrowserRouter([
	{
		path: 'login',
		element: <Login />
	},
	{
		path: 'login-error',
		element: <LoginError />
	},
	{
		path: 'sign-in',
		element: <SignIn />
	},
	{
		path: 'sign-up',
		element: <SignUp />
	},
	{
		path: 'complete-verification',
		element: <CompleteVerification />
	},
	{
		path: 'finish-verification',
		element: <FinishVerification />
	},
	{
		path: 'forgot-password',
		element: <ForgotPassword />
	},
	{
		path: 'password-reset-confirm',
		element: <ResetPassword />
	},
	{
		path: 'password-reset-complete',
		element: <CompletePasswordReset />
	},
	{ path: 'candidates/:id/share', element: <SharedCandidate /> },
	{
		path: '',
		element: <ProtectedRoute />,
		errorElement: <ErrorBoundary />,
		children: [
			{
				path: 'verify-email',
				element: <VerifyEmail />
			},
			{
				path: 'registration',
				element: <RegistrationLayout />,
				children: [
					{
						path: 'payment-success',
						element: <PaymentSuccess />
					},
					{
						path: 'payment-fail',
						element: <PaymentFail />
					}
				]
			},
			{
				path: 'registration/form',
				element: <RegistrationComplete />
			},
			{
				path: '',
				element: <MainLayout />,
				children: [
					{
						path: '',
						element: <Navigate to="search" replace />
					},
					{
						path: 'integrations',
						element: <ProtectedExpiredRoute />,
						children: [{ path: '', element: <Integrations /> }]
					},
					{
						path: 'technographics/chat',
						element: <ProtectedExpiredRoute />,
						children: [{ path: '', element: <TechnographicsChat /> }]
					},
					{
						path: 'search',
						element: <Search />
					},
					{
						path: 'history',
						element: <History />
					},
					{
						path: 'projects',
						element: <ProtectedExpiredRoute />,
						children: [
							{
								path: '',
								element: <ProjectsLayout />,
								children: [
									{
										path: '',
										element: <Navigate to="list" replace />
									},
									{
										path: 'list',
										element: <Projects />
									},
									{
										path: 'acquired',
										element: <ProjectsAcquired />
									},
									{
										path: ':id',
										children: [
											{
												path: '',
												element: <Navigate to="candidates" replace />
											},
											{
												path: 'candidates',
												element: <ProjectCandidates />
											}
										]
									}
								]
							}
						]
					},
					{
						path: 'registration/plan',
						element: <ProtectedRoute />,
						children: [{ path: '', element: <RegistrationPlan /> }]
					},
					{
						path: 'search/results',
						element: <ProtectedExpiredRoute />,
						children: [{ path: '', element: <Results /> }]
					},
					{
						path: 'profile',
						element: <ProtectedExpiredRoute />,
						children: [
							{
								path: '',
								element: <ProfileLayout />,
								children: [
									{
										path: '',
										element: <Navigate to="account" replace />
									},
									{
										path: 'account',
										element: <ProfileAccount />
									},
									{
										path: 'subscription',
										element: <ProfileSubscription />
									}
								]
							}
						]
					}
				]
			}
		]
	}
]);

export const Router = () => {
	const isMobile = useIsMobile();

	if (isMobile) return <MobileScreen />;

	return <RouterProvider router={router} />;
};

import { useEffect, useState } from 'react';

import { Icon, Text } from 'shared/components/ui';
import { SearchTag } from 'features/search';
import { useGetCandidatesQuery, useGetCompanyCandidatesQuery } from 'services';

import styles from './index.module.scss';

interface Props {
	tags: string[];
}

export const Sidebar = ({ tags }: Props) => {
	const [currentTags, setCurrentTags] = useState<string[]>(tags);
	const { data, isFetching, refetch } = useGetCandidatesQuery({
		required_skills: currentTags
	});

	const {
		data: companyData,
		isFetching: isCompanyFetching,
		refetch: companyRefetch
	} = useGetCompanyCandidatesQuery({
		skills: currentTags
	});

	const handleRemoveTag = (tag: string) => () => {
		setCurrentTags(prevTags => prevTags.filter(t => t !== tag));
	};

	useEffect(() => {
		refetch();
		companyRefetch();
	}, [currentTags, refetch, companyRefetch]);

	return (
		<div className={styles.sidebar}>
			<Text variant="inter/18/semi">Selected filters</Text>
			<div className={styles.filters}>
				<div className={styles.tags}>
					{currentTags.map(tag => (
						<SearchTag text={tag} onClick={handleRemoveTag(tag)} card />
					))}
				</div>
			</div>
			<div className={styles.results}>
				<div className={styles.resultsHeader}>
					<Text variant="inter/15/medium" color="grey">
						Search results count
					</Text>
				</div>
				<div className={styles.resultsCount}>
					<div className={styles.resultsRow}>
						<Icon icon="users" size={24} className={styles.resultsIcon} />
						<Text variant="inter/18/regular">
							{isFetching ? 'Loading...' : `${data?.results_count} People`}
						</Text>
					</div>
					<div className={styles.resultsRow}>
						<Icon icon="buildings" size={24} className={styles.resultsIcon} />
						<Text variant="inter/18/regular">
							{isCompanyFetching ? 'Loading...' : `${companyData?.results_count} Companies`}
						</Text>
					</div>
				</div>
			</div>
		</div>
	);
};

import { FC, ReactNode, useRef, useState, useEffect } from 'react';
import cn from 'classnames';

import { Icon } from 'shared/components/ui';
import { useAutosizeTextarea } from 'features/search/hooks';

import styles from './index.module.scss';

interface Props {
	isOpen: boolean;
	value?: string;
	onChange: (value: string) => void;
	openModal?: any;
	suffix?: ReactNode;
	variant: 'main' | 'side' | 'modal';
}

export const SearchText: FC<Props> = ({ isOpen, value, onChange, openModal, suffix, variant }: Props) => {
	const [isFocused, setIsFocused] = useState(false);
	const ref = useRef<HTMLTextAreaElement | null>(null);

	useAutosizeTextarea(ref.current, value || '');

	const handleFocus = () => {
		setIsFocused(true);

		if (variant === 'side' && !isOpen) {
			openModal();
		} else {
			ref.current?.focus();
		}
	};

	const handleBlur = () => {
		setIsFocused(false);
	};

	useEffect(() => {
		if (variant === 'modal' && !isFocused) {
			ref.current?.focus();
			if (ref.current) {
				const length = ref.current.value.length;
				ref.current.setSelectionRange(length, length);
			}
		}
	}, []);

	return (
		<div
			className={cn(styles.search, { [styles.searchOpen]: isOpen }, { [styles.searchFocus]: isFocused })}
			tabIndex={1}
			onFocus={handleFocus}
			onBlur={handleBlur}>
			<Icon icon="search" size={20} className={styles.searchIcon} />
			<div className={styles.wrapper}>
				<textarea
					ref={ref}
					autoFocus={variant === 'main'}
					onBlur={handleBlur}
					placeholder="Job title, skills, or any keywords"
					value={value ?? ''}
					onChange={e => onChange(e.target.value)}
					className={cn(styles.searchInput, { [styles.searchInputSmall]: variant === 'side' })}
				/>
			</div>
			{suffix}
		</div>
	);
};

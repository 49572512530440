import { useEffect } from 'react';

import { Chat } from 'features/technographics';
import { setHeaderTitle } from 'widgets/Header';
import { useAppDispatch } from 'store';

import styles from './index.module.scss';

export const TechnographicsChat = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setHeaderTitle(''));
	}, [dispatch]);

	return (
		<div className={styles.container}>
			<Chat />
		</div>
	);
};

import { createApi } from '@reduxjs/toolkit/query/react';

import { interceptor } from 'services/interceptor';
import { SearchParams, ProgAISearchResult } from 'shared/generated-models';

export const companySearchService = createApi({
	reducerPath: 'companySearchService',
	baseQuery: interceptor,
	tagTypes: ['CompanySearch'],
	endpoints: builder => ({
		getCompanyCandidates: builder.query<ProgAISearchResult, SearchParams>({
			query: params => ({
				url: '/technographics/',
				method: 'POST',
				body: params,
				invalidatesTags: ['CompanySearch']
			})
		})
	})
});

export const { useGetCompanyCandidatesQuery } = companySearchService;

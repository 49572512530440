import { useEffect, useState, useRef } from 'react';
import cn from 'classnames';

import { ChatText, Sidebar } from 'features/technographics';
import { Text } from 'shared/components/ui';
import { environment } from 'environments/environment';

import styles from './index.module.scss';

interface Message {
	id: string;
	text: string;
	sender: 'user' | 'bot';
	timestamp: Date;
}

const UserMessage = ({ value }: { value: string }) => {
	return (
		<div className={styles.userMessage}>
			<Text variant="inter/16/regular">{value}</Text>
		</div>
	);
};

const AssistantMessage = ({ value }: { value: string }) => {
	return (
		<div className={styles.assistantMessage}>
			<Text variant="inter/16/regular">{value}</Text>
		</div>
	);
};

export const Chat = () => {
	const [value, setValue] = useState<string>('');
	const [messages, setMessages] = useState<Message[]>([]);
	const [threadId, setThreadId] = useState<string | null>(null);
	const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);
	const messagesEndRef = useRef<HTMLDivElement | null>(null);
	const [tags, setTags] = useState<string[] | null>(null);

	const handleChange = (value: string) => {
		setValue(value);
	};

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	const sendMessage = async (userMessage: string, isFinal: boolean) => {
		try {
			const response = await fetch(`${environment.apiUrl}/chat/`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'text/event-stream'
				},
				body: JSON.stringify({
					message: userMessage,
					...(threadId && { thread_id: threadId })
				})
			});

			const reader = response.body?.getReader();
			const decoder = new TextDecoder();

			if (!reader) return;

			let currentResponse = '';

			while (true) {
				const { done, value } = await reader.read();
				if (done) break;

				const chunk = decoder.decode(value);
				const lines = chunk.split('\n\n');

				for (const line of lines) {
					if (line.startsWith('data: ')) {
						const data = JSON.parse(line.replace('data: ', ''));
						if (data.thread_id) {
							setThreadId(data.thread_id);
						} else if (data.text) {
							currentResponse = currentResponse + data.text;
							setMessages(prevMessages => {
								const lastMessage = prevMessages[prevMessages.length - 1];
								if (lastMessage && lastMessage.sender === 'bot') {
									return [
										...prevMessages.slice(0, prevMessages.length - 1),
										{
											...lastMessage,
											text: currentResponse,
											timestamp: new Date()
										}
									];
								} else {
									return [
										...prevMessages,
										{
											id: Date.now().toString(),
											text: currentResponse,
											sender: 'bot',
											timestamp: new Date()
										}
									];
								}
							});
						}
					}
				}
			}
			if (!isFinal) setButtonsDisabled(false);
			if (isFinal) setTags(currentResponse.split('\n'));
		} catch (error) {
			console.error('Fetch error:', error);
		}
	};

	const handleSubmit = () => {
		if (buttonsDisabled) return;

		setButtonsDisabled(true);

		const userMessage: Message = {
			id: Date.now().toString(),
			text: value,
			sender: 'user',
			timestamp: new Date()
		};

		setMessages(prev => [...prev, userMessage]);
		setValue('');

		const isFinal = value.toLowerCase().includes('final optional number');

		sendMessage(userMessage.text, isFinal);
	};

	const handleFinalize = () => {
		if (buttonsDisabled) return;
		setButtonsDisabled(true);
		const userMessage: Message = {
			id: Date.now().toString(),
			text: 'Final optional number',
			sender: 'user',
			timestamp: new Date()
		};
		setMessages(prev => [...prev, userMessage]);
		setValue('');
		sendMessage(userMessage.text, true);
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault();
			handleSubmit();
		}
	};

	const handleNext = () => {
		return;
	};

	return (
		<>
			{messages.length === 0 ? (
				<div className={styles.chat}>
					<Text variant="inter/22/medium">Start by finalising a list of tags</Text>
					<ChatText
						className={styles.chatInput}
						value={value}
						onChange={handleChange}
						variant="main"
						onKeyDown={handleKeyDown}
						suffix={
							<div
								onClick={handleSubmit}
								className={cn(styles.button, { [styles.buttonDisabled]: buttonsDisabled })}>
								<Text variant="inter/15/semi" color="white">
									Submit
								</Text>
							</div>
						}
					/>
				</div>
			) : (
				<div className={styles.container}>
					<div>header</div>
					<div className={styles.windowWrapper}>
						<div className={styles.window}>
							<div className={styles.messages}>
								{messages.map(message =>
									message.sender === 'user' ? (
										<UserMessage value={message.text} />
									) : (
										<AssistantMessage value={message.text} />
									)
								)}
								<div ref={messagesEndRef} />
							</div>
							<div
								onClick={handleFinalize}
								className={cn(styles.button, styles.buttonCenter, {
									[styles.buttonDisabled]: buttonsDisabled
								})}>
								<Text variant="inter/15/semi" color="white">
									Finalise tags
								</Text>
							</div>
							<ChatText
								className={styles.chatInput}
								value={value}
								onChange={handleChange}
								variant="chat"
								onKeyDown={handleKeyDown}
								suffix={
									<div
										onClick={handleSubmit}
										className={cn(styles.button, { [styles.buttonDisabled]: buttonsDisabled })}>
										<Text variant="inter/15/semi" color="white">
											Submit
										</Text>
									</div>
								}
							/>
						</div>
						{!!tags && tags.length > 0 && (
							<div className={styles.sidebarWrapper}>
								<Sidebar tags={tags} />
								<div onClick={handleNext} className={styles.button}>
									<Text variant="inter/15/semi" color="white">
										Next
									</Text>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};

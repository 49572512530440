import { FC, ReactNode, useRef, useState, useEffect } from 'react';
import cn from 'classnames';

import { useAutosizeTextarea } from 'features/search/hooks';

import styles from './index.module.scss';

interface Props {
	className?: string;
	value?: string;
	onChange: (value: string) => void;
	suffix?: ReactNode;
	variant: 'main' | 'chat';
	onKeyDown: any;
}

export const ChatText: FC<Props> = ({ className, value, onChange, suffix, variant, onKeyDown }: Props) => {
	const [isFocused, setIsFocused] = useState(false);
	const ref = useRef<HTMLTextAreaElement | null>(null);

	useAutosizeTextarea(ref.current, value || '');

	const handleFocus = () => {
		setIsFocused(true);
		ref.current?.focus();
	};

	const handleBlur = () => {
		setIsFocused(false);
	};

	useEffect(() => {
		if (variant === 'main' && !isFocused) {
			ref.current?.focus();
			if (ref.current) {
				const length = ref.current.value.length;
				ref.current.setSelectionRange(length, length);
			}
		}
	}, []);

	return (
		<div
			className={cn(className, styles.search, { [styles.searchFocus]: isFocused })}
			tabIndex={1}
			onFocus={handleFocus}
			onBlur={handleBlur}>
			<div className={styles.wrapper}>
				<textarea
					onKeyDown={onKeyDown}
					ref={ref}
					autoFocus={variant === 'main'}
					onBlur={handleBlur}
					placeholder="Enter your search"
					value={value ?? ''}
					onChange={e => onChange(e.target.value)}
					className={cn(styles.searchInput, { [styles.searchInputSmall]: variant === 'chat' })}
				/>
			</div>
			{suffix}
		</div>
	);
};
